import showdown from "showdown";
let converter = new showdown.Converter();
converter.setOption("noHeaderId", true);
converter.setOption("disableForced4SpacesIndentedSublists", true);
export default {
  data() {
    return {
      emojis: [
        "微笑",
        "撇嘴",
        "色",
        "发呆",
        "得意",
        "流泪",
        "害羞",
        "闭嘴",
        "睡",
        "大哭",
        "尴尬",
        "发怒",
        "调皮",
        "呲牙",
        "惊讶",

        "难过",
        "酷",
        "囧",
        "抓狂",
        "吐",
        "偷笑",
        "愉快",
        "白眼",
        "傲慢",
        "饥饿",
        "困",
        "惊恐",
        "流汗",
        "憨笑",
        "悠闲",

        "奋斗",
        "咒骂",
        "疑问",
        "嘘",
        "晕",
        "疯了",
        "衰",
        "骷髅",
        "敲打",
        "再见",
        "擦汗",
        "抠鼻",
        "鼓掌",
        "糗大了",
        "坏笑",

        "左哼哼",
        "右哼哼",
        "哈欠",
        "鄙视",
        "委屈",
        "快哭了",
        "阴险",
        "亲亲",
        "吓",
        "可怜",
        "菜刀",
        "西瓜",
        "啤酒",
        "篮球",
        "乒乓",

        "咖啡",
        "饭",
        "猪头",
        "玫瑰",
        "凋谢",
        "嘴唇",
        "爱心",
        "心碎",
        "蛋糕",
        "闪电",
        "炸弹",
        "刀",
        "足球",
        "瓢虫",
        "便便",

        "月亮",
        "太阳",
        "礼物",
        "拥抱",
        "强",
        "弱",
        "握手",
        "胜利",
        "抱拳",
        "勾引",
        "拳头",
        "差劲",
        "爱你",
        "NO",
        "OK",

        "爱情",
        "飞吻",
        "跳跳",
        "发抖",
        "怄火",
        "转圈",
        "磕头",
        "回头",
        "跳绳",
        "投降",
        "激动",
        "乱舞",
        "献吻",
        "左太极",
        "右太极",
      ],
    }
  },
  methods: {
    replaceAnswerText: function (text) {
      let that = this;
      if (!text) {
        return "";
      }
      text = converter.makeHtml(text);
      text = text.replace(/(?:(p|h1|h2|h3|ol|ul|li)>)\s+(?=<)/g, "$1>");
      text = text.replace(/\n/g, "<br/>");
      text = text.replace(/\[(\S*?)\]/g, function (arg1, arg2) {
        let _i = that.emojis.indexOf(arg2);
        if (_i == -1) {
          return arg1;
        } else {
          let y = Math.floor(_i / 15);
          let x = _i - y * 15;
          let img = `<img src="${require("@/assets/pic_blank.gif")}" style="width:1em;height:1em;font-size:28px;margin-top:-0.1em;vertical-align:middle;background:url(${require("@/assets/qq_face.png")}) no-repeat ${-x * 29
            }px ${-y * 29}px;background-size:435px 202px"/>`;
          return img;
        }
      });
      text = text.replace(/<a href=[\"\']weixin:\/\/bizmsgmenu\?msgmenucontent=(.+?)&msgmenuid=(.+?)[\"\']>(.+?)<\/a>/g, '<a href="javascript:void(0)" onclick="wxLinkClick(\'$1\')">$3</a>')
      text = text.replace(/<a\s*?href=[\"\']((http|https):\/\/[\w\-_]+(\.[\w\-_]+)+.*?)[\"\']>(.+?)<\/a>/g, '<a href="$1" target="_blank">$4</a>')
      return text;
    },
  },
}
